.gradient11 {
  /* background: linear-gradient(75deg, #238b21, #337c56); */
  background-color: hsla(106, 100%, 10%, 1);
  background-image: radial-gradient(
      at 92% 83%,
      hsla(183, 95%, 23%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 4% 6%, hsla(116, 62%, 26%, 1) 0px, transparent 50%),
    radial-gradient(at 92% 12%, hsla(155, 77%, 17%, 1) 0px, transparent 50%),
    radial-gradient(at 26% 60%, hsla(118, 77%, 19%, 1) 0px, transparent 50%);
}
