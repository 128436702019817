/* CSS for landing page outside  */
:root {
  --primary-color: #03393B;  
  --highlight-color: #238b21;
  --dark-color: #09263b;
  --gold-color: #e5ad52;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none !important;
  color: var(--dark-color);
}
a:hover {
  color: var(--primary-color);
}

.lightLink:hover {
  color: var(--gold-color);
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #fff inset; /* Change the color to your own background color */
  box-shadow: transparent;
  -webkit-text-fill-color: var(--dark-color);
  /* font-size: 13px; */
}
body {
  background: #fff;
  color: var(--dark-color);
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
  font-size: 16px;
  font-weight: 400;
}
.container {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0px 30px;
}
@media only screen and (max-width: 859px) {
  .container {
    padding: 0 15px;
  }
}
.light {
  font-weight: 300 !important;
}
.regular {
  font-weight: 400 !important;
}
.semiBold {
  font-weight: 600 !important;
}
.extraBold {
  font-weight: 800 !important;
}

/* HELLPERS */
.textCenter {
  text-align: center;
}
.animate {
  transition: 0.3s ease;
}
.pointer {
  cursor: pointer;
}
.relative {
  position: relative;
}
.textCenter {
  text-align: center;
}
.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}
.x100 {
  width: 100%;
}
.active-link {
  border-bottom: 2px solid var(--primary-color);
  color: var(--primary-color);
}
.tag {
  padding: 5px 10px;
  margin: 10px 0;
}
.shadow {
  box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -webkit-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
  -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5);
}
/* FLEXBOX */
.flex {
  display: flex;
}
.flexSpaceCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexSpaceAround {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flexNullCenter {
  display: flex;
  align-items: center;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexSpaceNull {
  display: flex;
  justify-content: space-between;
}

.flexEnd {
  display: flex;
  align-items: flex-end;
}

/* FONT SIZES */
.font11 {
  font-size: 0.688rem;
}
.font12 {
  font-size: 0.75rem;
}
.font13 {
  font-size: 0.813rem;
}
.font14 {
  font-size: 0.875rem;
}
.font15 {
  font-size: 0.938rem;
}
.font18 {
  font-size: 1.125rem;
}
.font20 {
  font-size: 1.25rem;
}
.font25 {
  font-size: 1.563rem;
}
.font30 {
  font-size: 1.875rem;
}
.font40 {
  font-size: 2.5rem;
}
.font60 {
  font-size: 3.75rem;
  line-height: 4.5rem;
}
@media only screen and (max-width: 859px) {
  .font11 {
    font-size: 0.688rem;
  }
  .font12 {
    font-size: 0.688rem;
  }
  .font13 {
    font-size: 0.75rem;
  }
  .font14 {
    font-size: 0.813rem;
  }
  .font15 {
    font-size: 0.875rem;
  }
  .font18 {
    font-size: 0.938rem;
  }
  .font20 {
    font-size: 1.125rem;
  }
  .font25 {
    font-size: 1.25rem;
  }
  .font30 {
    font-size: 1.5rem;
  }
  .font40 {
    font-size: 1.875rem;
  }
  .font60 {
    font-size: 2.8rem;
  }
}
/* BORDER RADIUS */
.radius8 {
  border-radius: 1rem;
}
.radius6 {
  border-radius: 0.6rem;
}
/* COLORS */
.darkColor {
  color: var(--dark-color);
}

.lightGoldColor {
  color: #ffe7ca;
}

.lightGoldBg {
  background-color: #ffe7ca !important;
  color: var(--dark-color) !important;
}

.goldColor {
  color: var(--gold-color);
}

.lightColor {
  color: #f5f5f5;
}

.mainColor {
  color: var(--primary-color);
}

.highlightColor {
  color: var(--highlight-color);
}
.greyColor {
  color: #707070;
}
.whiteColor {
  color: #fff;
}
/* BACKGROUNDS */
.darkBg {
  background-color: var(--dark-color);
}

.lightBg {
  background-color: #f5f5f5;
}
.coralBg {
  background-color: #c4ffff;
}
.greenBg {
  background-color: #49cb86;
}
.greyBg {
  background-color: #3e3e3e;
}

.darkGreenBg {
  background-color: var(--primary-color);
}
.whiteBg {
  background-color: #fff;
}

.access-tile:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.shadow {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.scaleUp:hover {
  transform: scale(1.05);
  z-index: 9;
}

.overflow {
  overflow: auto;
}

.wrap {
  flex-wrap: wrap;
}

/* GAPS */
.gap10 {
  gap: 10px;
}

.gap20 {
  gap: 20px;
}

.gap30 {
  gap: 30px;
}

/* DROPDOWN */

.sidebar {
  row-gap: 20px;
  /* column-gap: 10px; */
  align-content: flex-start;
  padding: 10px;
  bottom: 0;
  height: 93%;
}

.borderBox {
  border: 1px solid #e1e1e1;
}

.gradientText {
  background: linear-gradient(
    to right,
    var(--highlight-color),
    var(--primary-color)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.goldBg {
  background: linear-gradient(60deg, #e5cc9c, #a27e50);
}

.silverBg {
  background: linear-gradient(
    60deg,
    rgb(171, 184, 192) 0%,
    rgb(103, 117, 126) 100%
  );
}

.dropdown {
  padding: 25px 15px;
  justify-content: center;
  align-items: center;
  position: relative;
  /* perspective: 1000px; */
  z-index: 100;
}
.dropdown:hover {
  cursor: pointer;
}
.dropdown:hover .dropdown_menu li {
  display: block;
}
.dropdown_menu {
  position: absolute;
  top: 100%;
  left: -40%;
  width: 220px;
  perspective: 1000px;
  z-index: -1;
  box-shadow: rgba(145, 158, 171, 0.16) 0px 20px 40px;
}
.dropdown_menu li {
  display: none;
  padding: 10px 20px;
  opacity: 0;
  border-radius: 5px;
  margin: 5px;
  transition: 0.3s ease;
}
.dropdown_menu li:hover {
  background-color: rgba(0, 0, 0, 0.05);
  padding-left: 25px;
}
.dropdown:hover .dropdown_menu--animated {
  display: block;
}
.dropdown_menu--animated {
  display: none;
}
.dropdown_menu--animated li {
  display: block;
  opacity: 1;
}

.dropdown_menu-6 {
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}
@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  50% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

.background60 {
  position: absolute;
  top: 0;
  width: 100%;
  height: 60%;
  background-color: var(--primary-color);
}

.content {
  position: relative;
  z-index: 2;
}

/* Antd Override */
.sidebar_menu > .ant-menu-item {
  height: 50px;
}

.sidebar_menu .ant-menu-submenu .ant-menu-submenu-title {
  height: 50px;
}
